import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { VariableSizeList } from "react-window";
import { styled } from "@mui/material/styles";
// import { createFilterOptions } from '@mui/lab/Autocomplete';
import { matchSorter } from "match-sorter";
// import { Typography } from '@mui/material';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

// const useStyles = makeStyles({
//   listbox: {
//     boxSizing: 'border-box',
//     '& ul': {
//       padding: 0,
//       margin: 0,
//     },
//   },
// });

const useStyles = makeStyles((theme) => ({
  root: {
  },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  inputRoot: {
    color: "white",
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(23, 184, 135) !important", // outline when hovered
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(23, 184, 135) !important", // outline when clicked
    },
  },
}));


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '.MuiFilledInput-underline:after':{
      borderBottomColor: '#7a3c97',
  },
  '.MuiFilledInput-underline:before':{
      borderBottomColor: 'rgba(23, 184, 135, .5)',
  },
});

// const filterOptions = createFilterOptions({
//   matchFrom: 'start',
//   stringify: (option) => option.title,
// });

const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue);

// const renderGroup = (params) => [
//   <ListSubheader key={params.key} component="div">
//     {params.group}
//   </ListSubheader>,
//   params.children,
// ];

export default function TickerList({ stocks, selectedStock, setSelectedStock, setIsLoading }) {
  const classes = useStyles();

  return (
    <Autocomplete
      style={{ width: 300 }}
      disableListWrap
      defaultValue={"AAPL"}
      value={selectedStock}
      classes={classes}
      filterOptions={filterOptions}
      ListboxComponent={ListboxComponent}
      onChange={(e, newValue) => {
        setSelectedStock(newValue);
        if (newValue) {
          setIsLoading(true);
        }
      }}
      //   renderGroup={renderGroup}
      options={stocks}
      //   groupBy={(option) => option[0].toUpperCase()}
      renderInput={(params) => (
        <CustomTextField
          {...params} 
          variant='filled'
          label="Ticker Symbol"
          style={{ backgroundColor: "#21252b", color: "white" }}
        />
      )}
      //   renderOption={(option) => <Typography noWrap>{option}</Typography>}
    />
  );
}
