import React from "react"
// import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom"
// import Account from "./Account"
// import Login from "./Login"
// import Signup from "./Signup"
// import PrivateRoute from "./PrivateRoute"
// import ForgotPassword from"./ForgotPassword"
// import UpdateProfile from "./UpdateProfile"
import 'bootstrap/dist/css/bootstrap.min.css'
import MyNavbar from "./MyNavbar"
import Home from "./Home/Home"
import "./App.css"
import Formula from './Formula/Formula'
import MyFooter from "./MyFooter";
import TodoList from "./TodoList/TodoList"
import Stocks from "./Stocks/Stocks"


function App() {
  return ( 
  <>
    {/* <AuthProvider> */}
      <Router>
      <MyNavbar/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/formula-project" component={Formula} />
          <Route exact path="/stocks" component={Stocks} />
          <Route exact path="/todo-list" component={TodoList} />
          {/* <div className="app-main">
              <div className="app-content" >
                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute path="/account" component={Account} />
              </div>
          </div> */}
          {/* <Route path="/*" component={() => (<h2><b>404</b> - page not found </h2>)} /> */}
        </Switch>
        <MyFooter />
      </Router>
    {/* </AuthProvider> */}
  </>
  );
}

export default App;
