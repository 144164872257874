import React, { useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import './RefreshButton.css'

export default function RefreshButton(props) {

    const [buttonClick, setButtonClick] = useState(false)
    
    const handleClick =() => {
        props.setIsLoading(true)
        setButtonClick(true)
        props.handleStockChange(props.selectedStock)
        // const timer = setTimeout(() => { setButtonClick(false) }, 400)
        // return () =>{ clearTimeout(timer)}
    }

    return (
        <IconButton
            className={buttonClick ? 'rotate' : ''}
            onClick={handleClick}
            color="primary"
            aria-label="refresh"
            component="span"
            size="large">
          <RefreshIcon style={{ color: 'white', transform: 'rotate(45deg)' }} />
        </IconButton>
    );
}
