import { Helmet } from "react-helmet";
import "./Home.css";
import HomeExpText from "./HomeExpText";
import workExperiences from "./ExperienceData";
import ProjectCard from "./ProjectCard";
import AlphaProjectCard from "./AlphaProjectCard";
import Logos from "./Logos";
import Badges from "./Badges";
import pic from "../../images/headshot.png";

const listOfProjects = [
  {
    title: "F1 Leaderboards",
    link: "/formula-project",
    thumbnail: "/F12020_RedBull.jpg",
    description: (
      <>
        Check out the driver leaderboards for every season since 1950 based on the official{" "}
        <a
          href="https://documenter.getpostman.com/view/11586746/SztEa7bL"
          target="_blank"
          rel="noreferrer"
        >
          F1 API
        </a>{" "}
        data.
      </>
    ),
  },
  {
    title: "Todo List",
    link: "/todo-list",
    thumbnail: "/todo_list_project.jpg",
    description: (
      <>A simple application that lets you add and remove items from a list of thing to do.</>
    ),
  },
  {
    title: "Stock Tracker",
    link: "/stocks",
    thumbnail: "/stocks.jpg",
    description: (
      <>
        Take a look at the stock price of 5000 companies in the US market. Data provided from{" "}
        <a href="https://pypi.org/project/yfinance/" target="_blank" rel="noreferrer">
          yfinance
        </a>
        .
      </>
    ),
  },
];

export default function Home() {
  return (
    <div className="home-main">
      <Helmet title="Home" />

      <div className="home-bio">
        <img
          src={pic}
          alt="Stavros"
          width={175}
          height={175}
          style={{ border: "solid white 2px", borderRadius: "50%" }}
        />
        <div style={{ marginTop: 20 }}>
          <h2>Hello world! 🌍</h2>
          <p style={{ textAlign: "justify" }}>
            I'm a self taught programmer located in Luxembourg with origins from Athens, Greece. Currently, I'm working at{" "} 
            <a href="https://www.amazon.com" target="_blank" rel="noreferrer">
              Amazon
            </a>{" "}
            as a Software Developer.
            <br />
            <br />
            My academic background is in Survey Engineering with a specialization in Remote
            Sensing🛰. For my thesis, I worked on the fusion of LiDAR data with satellite imagery to
            improve the classification accuracy of CNNs.
          </p>
        </div>
      </div>

      <Logos />

      <div className="home-exp">
        <h1 style={{ fontSize: "calc(32px + 1vw)" }}>Work Experience 💪</h1>
        <div className="divider" style={{ width: "10%" }} />

        {workExperiences.map((work) => {
          return (
            <HomeExpText
              key={work.companyName}
              companyLogo={work.companyLogo}
              companyName={work.companyName}
              positionTitle={work.positionTitle}
              startDate={work.startDate}
              endDate={work.endDate}
              expText={work.expText}
            />
          );
        })}
      </div>

      <div className="home-projects-container">
        <h1 style={{ fontSize: "calc(32px + 1vw)" }}>Projects 💡</h1>
        <div className="divider" style={{ width: "15%", maxWidth: 115, marginBottom: "35px" }} />

        <div className="home-alpha-projects-container">
          <div className="home-projects-alpha">
            <AlphaProjectCard
              key="E-commerce app"
              title={"E-commerce App"}
              link="https://store.stavkats.com/"
              thumbnail="/amazon_clone.png"
              description={
                <>
                  Standalone online e-commerce app inspired by Amazon. Search for items, add them to
                  cart or save them for later. New features coming soon.
                </>
              }
            />
          </div>
          <div className="home-projects-alpha">
            <AlphaProjectCard
              key="Finance Tracker"
              reversed={true}
              title={"Finance Tracker"}
              link="https://fin.stavkats.com/"
              thumbnail="/doge_dollar.jpg"
              description={
                <>
                  Track your income, expenses along with your personal stock portofolio performance.
                </>
              }
            />
          </div>
        </div>

        <div className="home-projects-main">
          {listOfProjects.map((project) => {
            return (
              <div className="home-projects-animation">
                <ProjectCard
                  key={project.title}
                  title={project.title}
                  link={project.link}
                  thumbnail={project.thumbnail}
                  description={project.description}
                />
              </div>
            );
          })}
        </div>

        <div className="github-container">
          <img
            style={{ width: "89vw", maxWidth: "1000px" }}
            src="http://ghchart.rshah.org/0f7a0f/StavKats"
            alt="github contribution page"
          />
        </div>
        <p style={{ marginBottom: 40 }}>
          Powered by{" "}
          <a href="https://github.com/2016rshah/githubchart-api" target="_blank" rel="noreferrer">
            GitHub Chart API
          </a>
        </p>
      </div>

      <div className="home-certifications">
        <h1 style={{ fontSize: "calc(32px + 1vw)" }}>Certifications 🥇</h1>
        <div className="divider" style={{ width: "15%", maxWidth: 115, marginBottom: "35px" }} />

        <Badges />
      </div>

      {/* 
      <div>
        <Button variant='outlined' color='white' startIcon={<GetApp />}>
          <Link to="/thesis.pdf" target="_blank" download>Download</Link>
        </Button>
        
      </div> */}
    </div>
  );
}
