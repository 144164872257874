import React from "react";
import formulaLogo from "../../images/formula_logo.png";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  ".MuiFilledInput-underline:after": {
    borderBottomColor: "#7a3c97",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "rgba(23, 184, 135, .5)",
  },
});

export default function SeasonSelect(props) {
  const { seasons, screenWidth, handleChange } = props;
  return (
    <div className="formula-selection">
      <img src={formulaLogo} width={200} style={{ marginRight: 50 }} alt="Formula 1 logo" />
      <Autocomplete
        options={seasons}
        defaultValue={new Date().getFullYear().toString()}
        // getOptionLabel={(option) => {option}}
        style={{
          width: screenWidth <= 600 ? 200 : 300,
          marginTop: screenWidth <= 1000 ? "25px" : "0",
        }}
        onChange={handleChange}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            label="Formula 1 Seasons"
            variant="filled"
            style={{ backgroundColor: "#21252b" }}
          />
        )}
      />
    </div>
  );
}
