import { Skeleton, Typography } from "@mui/material";
import React from "react";

export default function DriverInfo(props) {
  const { driverPhoto, driverInfo, selectedDriverIndex, drivers, driverWiki, isLoading } = props;

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={"90%"} height={200}></Skeleton>
      ) : (
        <div className="driver-info">
          {driverPhoto && (
            <img style={{ paddingRight: 15 }} height={200} src={driverPhoto} alt=""></img>
          )}

          {driverInfo && (
            <Typography style={{ fontSize: "14px" }} align="justify">
              {selectedDriverIndex !== -1 && (
                <Typography variant="h4">
                  {`${drivers[selectedDriverIndex]["givenName"]} ${drivers[selectedDriverIndex]["familyName"]}`}
                </Typography>
              )}
              {driverInfo}
              {driverWiki && (
                <i style={{ float: "right", marginTop: 10 }}>
                  <br />
                  Source:{" "}
                  <a href={driverWiki} target="_blank" rel="noreferrer">
                    {" "}
                    Wikipedia
                  </a>
                </i>
              )}
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
