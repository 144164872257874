import React from "react";
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

export default function NationalitiesGraph({ nationalities, screenWidth }) {
  return (
    <Plot
      backgroundColor="red"
      data={[
        {
          type: "bar",
          name: "Nationality count",
          text: nationalities.y,
          textposition: "outside",
          hovertext: "count",
          x: nationalities.x,
          y: nationalities.y,
          marker: { color: "rgba(255,153,51,0.6)", width: 1 },
        },
      ]}
      layout={{
        width:
          screenWidth > 1000
            ? nationalities.x.length * 65
            : nationalities.x.length * 65 * Math.max(screenWidth / 1000, 0.35),
        height: 350,
        title: "Nationalities",
        font: { color: "white", size: 16 },
        // autosize: true,
        // useResizeHandler: true,
        // width: '100%', height: '100%',
        paper_bgcolor: "rgba(0,0,0,0)", //#424242
        plot_bgcolor: "rgba(0,0,0,0)",
        yaxis: {
          visible: false,
          range: [0, Math.floor(Math.max.apply(null, nationalities.y) * 1.1 + 1)],
        },
      }}
    />
  );
}
