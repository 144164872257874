import { Typography } from '@mui/material';
import React from 'react'
import './MyFooter.css';

export default function MyFooter() {
    return (
        <div className='footer-main'>
            <Typography style={{fontSize: '13px'}} variant='subtitle1' >Designed & Developed by Stavros Katsantonis</Typography>            
        </div>
    )
}
