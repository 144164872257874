import React, { useState, useEffect, useCallback } from "react";
import ChartButtons from "./ChartButtons";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts";

const dateFormatter = (date) => {
  date = new Date(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return year + (month >= 10 ? "-" : "-0") + month + (day >= 10 ? "-" : "-0") + day;
};
const topOffset = 1.05;
const bottomOffest = 0.95;

export default function HistoryChart({ stockData }) {
  const [zoomData, setZoomData] = useState({
    data: stockData,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    top:
      Math.round(
        stockData.reduce((p, c) => (p.price > c.price ? p : c))["price"] * topOffset * 100
      ) / 100,
    bottom:
      Math.round(
        stockData.reduce((p, c) => (p.price < c.price ? p : c))["price"] * bottomOffest * 100
      ) / 100, // animation: true
  });
  const { data, left, right, refAreaLeft, refAreaRight, top, bottom } = zoomData;
  const [zoomLevel, setZoomLevel] = useState("All");

  useEffect(() => {
    setZoomData((prevState) => {
      return {
        ...prevState,
        data: stockData,
        top:
          Math.round(
            stockData.reduce((p, c) => (p.price > c.price ? p : c))["price"] * topOffset * 100
          ) / 100,
        bottom:
          Math.round(
            stockData.reduce((p, c) => (p.price < c.price ? p : c))["price"] * bottomOffest * 100
          ) / 100,
      };
    });
  }, [stockData]);

  const zoomAll = useCallback(() => {
    setZoomData((prevState) => {
      return {
        refAreaLeft: "",
        refAreaRight: "",
        left: "dataMin",
        right: "dataMax",
        data: prevState.data.slice(),
        top:
          Math.round(
            stockData.reduce((p, c) => (p.price > c.price ? p : c))["price"] * topOffset * 100
          ) / 100,
        bottom:
          Math.round(
            stockData.reduce((p, c) => (p.price < c.price ? p : c))["price"] * bottomOffest * 100
          ) / 100,
      };
    });
  }, [stockData]);

  const filterData = useCallback(
    (date) => {
      let tempData = stockData.filter((el) => {
        return el.date >= date.valueOf();
      });

      setZoomData((prevState) => {
        return {
          refAreaLeft: "",
          refAreaRight: "",
          left: tempData[0]["date"],
          right: tempData[tempData.length - 1]["date"],
          data: prevState.data.slice(),
          bottom:
            Math.round(
              tempData.reduce((p, c) => (p.price < c.price ? p : c))["price"] * bottomOffest * 100
            ) / 100,
          top:
            Math.round(
              tempData.reduce((p, c) => (p.price > c.price ? p : c))["price"] * topOffset * 100
            ) / 100,
        };
      });
    },
    [stockData]
  );

  // handle button clicks
  useEffect(() => {
    if (zoomLevel === "All") {
      zoomAll();
    } else if (zoomLevel !== null) {
      let tempDate = new Date();
      if (zoomLevel === "5Y") {
        tempDate.setFullYear(tempDate.getFullYear() - 5);
      } else if (zoomLevel === "1Y") {
        tempDate.setFullYear(tempDate.getFullYear() - 1);
      } else if (zoomLevel === "6M") {
        tempDate.setMonth(tempDate.getMonth() - 6);
      } else if (zoomLevel === "1M") {
        tempDate.setMonth(tempDate.getMonth() - 1);
      } else if (zoomLevel === "5D") {
        tempDate.setDate(tempDate.getDate() - 7);
      }
      tempDate.setDate(tempDate.getDate() - 1);
      filterData(tempDate);
    }
  }, [zoomLevel, filterData, zoomAll]);

  const getAxisYDomain = (from, to, ref) => {
    from = stockData.findIndex((obj) => obj.date === from);
    to = stockData.findIndex((obj) => obj.date === to);
    const refData = stockData.slice(from - 1, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
    return [bottom * bottomOffest, top * topOffset];
  };

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = zoomData;
    const { data } = zoomData;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setZoomData((prevState) => {
        return { ...prevState, refAreaLeft: "", refAreaRight: "" };
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "price");
    setZoomLevel(null);
    setZoomData({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom: Math.round(bottom * 100) / 100,
      top: Math.round(top * 100) / 100,
    });
  };

  const handleMouseDown = (e) => {
    if (e && window.screen.width > 600) {
      setZoomData((prevState) => {
        return { ...prevState, refAreaLeft: e.activeLabel };
      });
    }
  };

  const handleMouseMove = (e) => {
    if (window.screen.width > 600 && refAreaLeft) {
      setZoomData((prevState) => {
        return { ...prevState, refAreaRight: e.activeLabel };
      });
    }
  };

  const handleMouseUp = () => {
    if (window.screen.width > 600) {
      zoom();
    }
  };

  return (
    <div
      style={{
        userSelect: "none",
        width: "100%",
        height: '83vh',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <ChartButtons setZoomLevel={setZoomLevel} zoomLevel={zoomLevel} />

      <ResponsiveContainer width="100%" height='92%'>
        <AreaChart
          margin={{ left: 25 }}
          data={data}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <XAxis
            dy={5}
            minTickGap={50}
            tickCount={13}
            allowDataOverflow
            dataKey="date"
            domain={[left, right]}
            type="number"
            tickFormatter={dateFormatter}
          />{" "}
          <YAxis
            minTickGap={100}
            tickCount={13}
            dx={-5}
            allowDataOverflow
            dataKey="price"
            domain={[bottom, top]}
            type="number"
            yAxisId="1"
          />
          <Tooltip
            labelFormatter={dateFormatter}
            contentStyle={{ backgroundColor: "#424242", borderColor: "424242" }}
          />
          {/* <defs>
                        <linearGradient id="colorUv" gradientTransform="rotate(90)">
                            <stop offset="20%" stopColor="rgba(136, 132, 216,0.2)" stopOpacity={0.1}/>
                            <stop offset="90%" stopColor="rgb(136, 132, 216)" stopOpacity={0.5}/>
                        </linearGradient>
                    </defs> */}
          <Area
            yAxisId="1"
            // type="monotone"
            dataKey="price"
            stroke="#8884d8"
            // fill="url(#colorUv)" fillOpacity={1}
            fill="rgba(136, 132, 216,0.1)"
            animationDuration={800}
            dot={false}
          />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
