import React from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

  const CustomToggleButton = styled(ToggleButton)({
    color: 'rgba(255,255,255,0.95)',
    '&:selected': {
      color: 'black'
    },
    '&.Mui-selected': {
      color: '#33ffd0',
      backgroundColor: 'rgb(144 249 234 / 16%)',

    }
  });

export default function ChartButtons({ zoomLevel, setZoomLevel}) {

    const handleChange = (event, newZoomLevel) => {
        if(newZoomLevel !== null){
            setZoomLevel(newZoomLevel);
        }
    };

    return (
        <ToggleButtonGroup 
            color="primary"
            value={zoomLevel}
            exclusive
            size='small'
            onChange={handleChange}
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', marginLeft: 100, marginBottom: 20 }}
            >
            <CustomToggleButton value="All">All</CustomToggleButton>
            <CustomToggleButton value="5Y">5Y</CustomToggleButton>
            <CustomToggleButton value="1Y">1Y</CustomToggleButton>
            <CustomToggleButton value="6M">6M</CustomToggleButton>
            <CustomToggleButton value="1M">1M</CustomToggleButton>
            {/* <CustomToggleButton value="5D">5D</CustomToggleButton> */}
        </ToggleButtonGroup >
    )
}
