import { React, useState } from "react";
import classes from "./HomeExpText.module.css";

const calcTimeAtJob = (startDate, endDate) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let [startMonth, startYear] = startDate.split(" ");
  let [endMonth, endYear] = endDate.split(" ");
  let expInMonths;

  if (endMonth === "Present") {
    endMonth = months[new Date().getMonth()];
    endYear = new Date().getFullYear().toString();
  }

  const startMonthNumber = months.indexOf(startMonth);
  const endMonthNumber = months.indexOf(endMonth);
  if (startYear !== endYear) {
    const yearsToMonths = (parseInt(endYear) - parseInt(startYear)) * 12;
    const remainingMonths = startMonthNumber - endMonthNumber;
    expInMonths = yearsToMonths - remainingMonths + 1;
  } else {
    expInMonths = endMonthNumber - startMonthNumber + 1;
  }

  let expYears = parseInt(expInMonths / 12);
  expInMonths = expInMonths % 12;
  let monthText = expInMonths > 0 ? `${expInMonths} ${expInMonths === 1 ? "month" : "months"}` : "";
  let yearText = expYears > 0 ? `${expYears} ${expYears === 1 ? "year" : "years"}` : "";

  return `${yearText} ${monthText}`.trim();
};

export default function HomeExpText(props) {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  window.addEventListener("resize", () => setScreenWidth(window.screen.width));

  const resizeImage = (originalSize) => {
    return originalSize * Math.min(screenWidth / 400, 1);
  };

  const timeAtJob = calcTimeAtJob(props.startDate, props.endDate);

  return (
    <div className={classes.base}>
      <div className={classes.main}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.companyLogo && (
            <img
              src={props.companyLogo}
              width={resizeImage(120)}
              height={resizeImage(120)}
              style={{ marginRight: 20 }}
              alt="company logo"
              className={classes.exp_company_logo}
            />
          )}

          <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
            {screenWidth <= 600 ? <h5>{props.companyName}</h5> : <h4>{props.companyName}</h4>}

            <p>
              {props.positionTitle}
              <br />
              {props.startDate} - {props.endDate}
              {screenWidth <= 600 && <br />}
              {timeAtJob && ` (${timeAtJob})`}
              <br />
              Full-Time
            </p>
          </div>
        </div>
      </div>

      <div className={classes.exp_text}>{props.expText}</div>

      {/* <div style={{ width: "100%", borderBottom: "1px solid grey", marginTop: "2%" }} /> */}
    </div>
  );
}
