import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./TodoList.css";
import { IconButton } from "@mui/material";
import TodoItem from "./TodoItem";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const defaultTodoItems = [
  { id: 0, value: "Explore the universe 🌌" },
  { id: 1, value: "Cure cancer 💊" },
  { id: 2, value: "Ride the waves 🌊" },
];

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#282c34",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgb(23, 184, 135)",
  },
});

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box
        style={{ border: "solid", borderColor: "gray", borderWidth: 1, borderRadius: 10 }}
        width="100%"
        mr={1}
      >
        <LinearProgress
          classes={{ root: classes.root, colorPrimary: classes.colorPrimary, bar: classes.bar }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography style={{ color: "white" }} variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function TodoList() {
  const [newItem, setNewItem] = useState("");
  const [todoList, setTodoList] = useState(defaultTodoItems);
  
  const getItemId = () => {
    if (todoList.length > 0) {
      let ids = todoList.map((item) => item.id);
      return Math.max.apply(null, ids) + 1;
    }
    return 0;
  };

  const handleChange = (event) => setNewItem(event.target.value);

  const handleAdd = () => {
    if (newItem) {
      setTodoList([...todoList, { id: getItemId(), value: newItem, completed: false }]);
      setNewItem("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.keyCode === "13" || event.keyCode === 13){
        handleAdd();
    }
  }

  useEffect(() => {
    console.log(todoList)
  }, [todoList])

  return (
    <div className="todo-home">
      <div className="todo-main" /* style={{ height: `${Math.max(500, todoList ? todoList.length * 150 : 0)}px`}}  */ >
        <div className="todo-progress">
          {todoList.length === 0 ? (
            <h3 style={{ marginBottom: 25 }}>Nothing to do, time to rest 😴</h3>
          ) : (
            <>
              <span>
                <h3>
                  {todoList.filter((el) => el.completed).length === todoList.length && <>🎉 </>}
                  Completed ({todoList.filter((el) => el.completed).length}/{todoList.length})
                  {todoList.filter((el) => el.completed).length === todoList.length && <> 🎉</>}
                </h3>
              </span>

              <div className='my-progress-bar'>
                <LinearProgressWithLabel
                  value={(100 * todoList.filter((el) => el.completed).length) / todoList.length}
                />
              </div>
            </>
          )}
        </div>

        <div style={{ width: "100%" }}>
          <div className="todo-items-container">
            {todoList.map((item) => ( <TodoItem key={item.id} setTodoList={setTodoList} todoItem={item} todoList={todoList} /> ))}
          </div>

          <div className="todo-input-container">
            <div>
              <input type="text" className="todo-input" placeholder="Add an item" value={newItem}
                onChange={handleChange} onKeyDown={handleKeyDown} ></input>
            </div>

            <div className="add-icon">
              <IconButton size="small" onClick={handleAdd}>
                <AddIcon style={{ fill: "green" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
