import pythonLogo from "../../images/python.png";
import reactLogo from "../../images/react.png";
import mongoLogo from "../../images/mongo.png";
import dockerLogo from "../../images/docker.png";
import sqlLogo from "../../images/sql_server.png";
import azureLogo from "../../images/azure.png";
import gitLogo from "../../images/git.png";
import Tooltip from "@mui/material/Tooltip";

import classes from "./Logos.module.css";

const logos1 = [
  { img: pythonLogo, txt: "Python", placement: "top", dim: 70 },
  { img: reactLogo, txt: "React", placement: "top", dim: 70 },
  { img: mongoLogo, txt: "MongoDB", placement: "top", dim: 70 },
  { img: sqlLogo, txt: "SQL-Server", placement: "top", dim: 70 },
];

const logos2 = [
  { img: dockerLogo, txt: "Docker", placement: "bottom", dim: 70 },
  { img: azureLogo, txt: "Azure", placement: "bottom", dim: 65 },
  { img: gitLogo, txt: "Git", placement: "bottom", dim: 55 },
];

const MyTooltip = ({ img, txt, placement, dim }) => {
  return (
    <Tooltip className={classes.logo} title={<span style={{ fontSize: "14px" }}>{txt}</span>} placement={placement}>
      <img src={img} alt={`${txt} logo`} width={dim} height={dim} />
    </Tooltip>
  );
};

export default function Logos() {
  return (
    <div className={classes.main}>
      <div className={classes.first_section}>
        {logos1.map((logo) => {
          return (
            <MyTooltip img={logo.img} key={logo.txt} txt={logo.txt} placement={logo.placement} dim={logo.dim} />
          );
        })}
      </div>

      <div className={classes.second_section} style={{ marginTop: "25px" }}>
      {logos2.map((logo) => {
          return (
            <MyTooltip img={logo.img} key={logo.txt} txt={logo.txt} placement={logo.placement} dim={logo.dim} />
          );
        })}
      </div>
    </div>
  );
}
