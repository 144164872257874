import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { CardMedia } from "@mui/material";
import classes from "./AlphaProjectCard.module.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function AlphaProjectCard({ title, description, link, thumbnail, reversed }) {
  return (
    <div className={reversed ? classes["block_reversed"] : classes["block"]}>
      <a href={link} target="_blank" rel="noreferrer">
        <CardMedia style={{ height: 160 }} image={thumbnail} />
      </a>

      <div style={{ height: "100%", padding: "20px 20px 0px 20px" }}>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        <Typography align="justify">{description}</Typography>
      </div>

      <div className={classes["gradient-border"]} style={{ margin: "0px 0px 10px 10px" }}>
        <Button href={link} target="_blank" rel="noreferrer" size="small">
          Go to project
          <OpenInNewIcon style={{ marginLeft: 3 }} fontSize="small" />
        </Button>
      </div>
    </div>
  );
}
