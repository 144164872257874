import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import { useAuth } from "../contexts/AuthContext"
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// import logo from "../images/headshot.png"
import "./MyNavbar.css";

export default function MyNavbar() {
  // const { currentUser, logout } = useAuth()
  // const [error, setError] = useState('')
  // const history = useHistory()

  // async function handleLogout(){
  //     setError('')

  //     try{
  //         await logout() // await works only inside async functions
  //         history.push('/login')
  //     }catch{
  //         setError('Failed to log out')
  //     }
  // }

  // const nav1 = currentUser ?  <Nav.Link href="/account">Account</Nav.Link>: <Nav.Link href="/login">Log In</Nav.Link>
  // const nav2 = currentUser ?  <Nav.Link onClick={handleLogout}>Log Out</Nav.Link>: <Button variant="secondary" href="/signup">Sign Up</Button>

  return (
    <>
      <Navbar className='sticky-nav' collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Stavros Katsantonis
          </Link>
          {/* <img alt="" src={logo} width="30" height="30"className="d-inline-block align-top" />{' '} */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link className="nav-link" to="/">
              Home
            </Link>
            <NavDropdown title="Projects">
              <NavDropdown.Item as={Link} to="/formula-project" style={{ backgroundColor: "#343a40" }}>
                Formula
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/todo-list" style={{ backgroundColor: "#343a40" }}>
                Todo List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/stocks" style={{ backgroundColor: "#343a40" }}>
                Stocks Tracker
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link target="_blank" href="https://www.linkedin.com/in/stavros-katsantonis-0b0431111/" style={{ marginRight: "50px" }}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon style={{ marginRight: "5" }} />
                LinkedIn
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
