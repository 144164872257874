import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react'
import { CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProjectCard({title, description, link, thumbnail}) {
    return (
        <Card style={{ display: 'flex', flexDirection: 'column',justifyContent:'space-between',
                        margin: 35, minWidth: 300, maxWidth: 300, height: '100%', 
                        minHeight: 352, maxHeight: 352,
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 15px 25px',}} variant="outlined">
            
            <Link to={link} onClick={() => {window.scrollTo(0, 0)}}>
                <CardMedia style={{height: 160}}  image={thumbnail} />
            </Link>

            <CardContent style={{height: '100%'}}>

                <Typography gutterBottom variant="h5" component="h2">{title}</Typography>
                
                <Typography align='justify' >
                    {description}
                </Typography>
                
                <Typography color='textSecondary' style={{fontSize: '14px', marginTop: 15, marginBottom: -15}}>
                    {/* <i>(Work in progress)</i> */}
                </Typography>

            </CardContent>

            <CardActions>
                <Button component={Link} to={link} size="small" 
                        onClick={() => {window.scrollTo(0, 0)}}>Go to page</Button>
            </CardActions>
            
      </Card>
    )
}
