import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton } from "@mui/material";
import classes from "./TodoItemButtons.module.css";

export default function TodoItemButtons({ todoItem, todoList, setTodoList }) {
  const toggleStatus = () => {
    let tempItem = todoList.filter((el) => el.id === todoItem.id)[0];
    tempItem = { ...tempItem, completed: !tempItem.completed };

    let tempList = todoList.filter((el) => el.id !== todoItem.id);
    tempList = [...tempList, tempItem];
    setTodoList(tempList.sort((a, b) => (a.id > b.id ? 1 : -1)));
  };

  const handleRemove = () => setTodoList(todoList.filter((el) => el.id !== todoItem.id));

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.icons}>
        <IconButton size="small" onClick={toggleStatus}>
          <CheckIcon style={{ fill: "green" }} />
        </IconButton>
      </div>

      <div className={classes.icons} style={{ marginLeft: 5 }}>
        <IconButton size="small" onClick={handleRemove}>
          <DeleteIcon style={{ fill: "#d62f2b" }} />
        </IconButton>
      </div>
    </div>
  );
}
