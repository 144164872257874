import React, { useEffect, useState } from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import "./Formula.css";
import FormulaTable from "./FormulaTable";
import { Typography } from "@mui/material";
import NationalitiesGraph from "./NationalitiesGraph";
import DriverInfo from "./DriverInfo";
import SeasonSelect from "./SeasonSelect";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const updateNationalities = (drivers, setNationalities) => {
  let tmpNationalities = valueCountArray(
    drivers.map((driver) => {
      return driver.nationality;
    })
  );
  setNationalities({
    x: tmpNationalities.map((item) => {
      return item.nationality;
    }),
    y: tmpNationalities.map((item) => {
      return item.count;
    }),
  });
};
const valueCountArray = (array) => {
  let res = [];
  let unique = array.filter(onlyUnique);
  for (let i = 0; i < unique.length; i++) {
    res.push({
      nationality: unique[i],
      count: array.filter((val) => val === unique[i]).length,
    });
  }
  return res;
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const formatDrivers = (drivers, standings) => {
  return drivers.map((driver) => {
    let standingsData = standings.filter((standing) => {
      return standing["Driver"]["driverId"] === driver["driverId"];
    });
    const constructor = standingsData[0].Constructors[0];
    return {
      ...driver,
      constructor: constructor.name,
      constructorId: constructor.constructorId,
      points: standingsData[0].points,
    };
  });
};

export default function Formula() {
  const [seasons, setSeasons] = useState([]);
  const [drivers, setDrivers] = useState();
  const [nationalities, setNationalities] = useState();
  const [selectedSeason, setSelectedSeason] = useState(new Date().getFullYear().toString());
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [driverPhoto, setDriverPhoto] = useState();
  const [driverInfo, setDriverInfo] = useState();
  const [driverWiki, setDriverWiki] = useState();
  const [selectedDriverIndex, setSelectedDriverIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [seasonIsLoading, setSeasonIsLoading] = useState(true);

  // Get seasons
  useEffect(() => {
    fetch("https://ergast.com/api/f1/seasons.json?limit=200")
      .then((res) => res.json())
      .then((data) => {
        setSeasons(
          data["MRData"]["SeasonTable"]["Seasons"]
            .map((item) => {
              return item.season;
            })
            .reverse()
        );
      });
  }, []);

  // Driver data search
  useEffect(() => {
    if (selectedDriverIndex === -1) {
      setDriverPhoto();
      setDriverInfo();
      setDriverWiki();
    } else if (drivers) {
      fetch(
        `${process.env.REACT_APP_AZURE_API_URL}/driverImage?driver_id=${drivers[selectedDriverIndex].driverId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setDriverPhoto(data.imageURL);
          setDriverInfo(data.driverInfo);
          setDriverWiki(data.wikiURL);
          setIsLoading(false);
          // console.log(drivers[selectedDriverIndex])
        });
    }
  }, [selectedDriverIndex, drivers]);

  // Get data for selected season
  useEffect(() => {
    if (selectedSeason) {
      setDriverPhoto();
      setDriverInfo();
      setDriverWiki();
      fetch(`https://ergast.com/api/f1/${selectedSeason}/drivers.json`)
        .then((res) => res.json())
        .then((data) => {
          let drivers = data["MRData"]["DriverTable"]["Drivers"];

          // add constructor's name and driver's points
          fetch(`https://ergast.com/api/f1/${selectedSeason}/driverStandings.json`)
            .then((res) => res.json())
            .then((data) => {
              let standings = data.MRData.StandingsTable.StandingsLists[0].DriverStandings;
              drivers = formatDrivers(drivers, standings);
              setDrivers(drivers);
              setSeasonIsLoading(false);
            });

          updateNationalities(drivers, setNationalities);
        });
    } else setDrivers();
  }, [selectedSeason]);

  const handleChange = (_, newVal) => {
    setSeasonIsLoading(true);
    setSelectedSeason(newVal);
  };

  window.addEventListener("resize", () => {
    setScreenWidth(window.screen.width);
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Helmet title="Formula 1 Project" />
        <div className="formula-main">
          <SeasonSelect seasons={seasons} screenWidth={screenWidth} handleChange={handleChange} />

          <div className="formula-tng">
            {drivers && (
              <div className="formula-left">
                {seasonIsLoading ? (
                  <Skeleton variant="rectangular" width="95%" height="70vh"></Skeleton>
                ) : (
                  <FormulaTable
                    drivers={drivers}
                    setSelectedDriverIndex={setSelectedDriverIndex}
                    setIsLoading={setIsLoading}
                  />
                )}
              </div>
            )}

            <div className="formula-right">
              {seasonIsLoading ? (
                <Skeleton variant="rectangular" height={250} width="85%"></Skeleton>
              ) : (
                nationalities && (
                  <NationalitiesGraph nationalities={nationalities} screenWidth={screenWidth} />
                )
              )}

              {!seasonIsLoading && selectedDriverIndex === -1 ? (
                <div className="driver-selection">
                  <Typography variant="overline" color="textSecondary">
                    <i>(Select a driver to get more details)</i>
                  </Typography>
                </div>
              ) : (
                <DriverInfo
                  driverInfo={driverInfo}
                  driverPhoto={driverPhoto}
                  driverWiki={driverWiki}
                  drivers={drivers}
                  selectedDriverIndex={selectedDriverIndex}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
