import signalLogo from "../../images/signal.jpg";
import eydapLogo from "../../images/eydap.jpg";
import intrasoftLogo from "../../images/intrasoft.jpg";
import eyLogo from "../../images/ey.png";
import amazonLogo from "../../images/amazon.png";

const signalExpText = (
  <ul>
    <li>Full Stack developing using ReactJS, Python, SQL-Server, MongoDB and Docker</li>
    <li>Large dataset analyses</li>
    <li>Building forecasting/prediction algorithms</li>
    <li>DevOps responsibilities using Microsoft Azure</li>
    <li>Agile development</li>
    <li>Founder member of a new startup venture</li>
  </ul>
);

const eydapExpText = (
  <ul>
    <li>Created daily and weekly reports of the damages in the sewerage system</li>
    <li>Updated the company’s database using SAP Business One</li>
    <li>Increased efficiency and productivity by developing a new workflow of the repair team</li>
    <li>Assisted the department’s manager in her everyday tasks and ad-hoc reports</li>
  </ul>
);

const intrasoftExpText = (
  <ul>
    <li>
      Developed new core functionalities on the mobile application used by COSMOTE S.A. technicians
      on site (full-stack development using Python's Flask, Angular JS, CSS, HTML, PL/SQL)
    </li>
    <li>Maintained the COSMOTE S.A. production database using Oracle PL/SQL</li>
    <li>Implemented test scenarios in SIT/UAT environments using Oracle PL/SQL</li>
    <li>
      Co-developed the Product Backlog Items requested from COSMOTE S.A. along with necessary fixes
      for each PBI using Oracle PL/SQL
    </li>
  </ul>
);

const eyExpText = (
  <ul>
    <li>Backend developing using PL/SQL</li>
    <li>Frontend customizations using React & TypeScript</li>
    <li>IT Consulting</li>
  </ul>
);

const amazonExpText = (
  <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
    <p style={{ fontSize: "32px" }}>
      🚧{" "}
      <span>
        <i style={{ fontSize: "18px" }}>Under development</i>
      </span>{" "}
      🚧
    </p>
  </div>
);

const workExperiences = [
  {
    companyLogo: amazonLogo,
    companyName: "🛒 Amazon",
    positionTitle: "Software Developer",
    startDate: "May 2022",
    endDate: "Present",
    expText: amazonExpText,
  },

  {
    companyLogo: eyLogo,
    companyName: "🏦 Ernst & Young",
    positionTitle: "Junior Software Engineer",
    startDate: "Jul 2021",
    endDate: "Apr 2022",
    expText: eyExpText,
  },

  {
    companyLogo: signalLogo,
    companyName: "🚢 The Signal Group",
    positionTitle: "Data Trainee II (Full-Stack Developer)",
    startDate: "Oct 2020",
    endDate: "Jun 2021",
    expText: signalExpText,
  },

  {
    companyLogo: intrasoftLogo,
    companyName: "📞 Intrasoft International",
    positionTitle: "Junior Software Engineer",
    startDate: "Feb 2020",
    endDate: "Apr 2020",
    expText: intrasoftExpText,
  },

  {
    companyLogo: eydapLogo,
    companyName: "💧 EYDAP",
    positionTitle: "Business Analyst Intern",
    startDate: "Jul 2019",
    endDate: "Aug 2019",
    expText: eydapExpText,
  },
];

export default workExperiences;
