import Tooltip from "@mui/material/Tooltip";
import awsCloudPractitioner from "../../images/aws-certified-cloud-practitioner.png";
import az900 from "../../images/az-900.png";
import dp900 from "../../images/dp-900.png";
import classes from "./Badges.module.css";

export default function Badges() {
  const badges = [
    {
      title: "AWS Cloud Practitioner",
      image: awsCloudPractitioner,
      url: "https://www.credly.com/badges/7ea30837-5f10-4591-976e-c7c583df9d5b/public_url",
    },
    {
      title: "Microsoft Azure Fundamentals",
      image: az900,
      url: "https://www.credly.com/badges/a00cdf4e-7766-407a-b2b9-f00f6aa96ed2/public_url",
    },
    {
      title: "Microsoft Azure Data Fundamentals",
      image: dp900,
      url: "https://www.credly.com/badges/3805d614-4809-4bb5-a2c6-d030c6749514/public_url",
    },
  ];

  return (
    <div className={classes.container}>
      {badges.map((badge) => (
        <a target="_blank" rel="noreferrer" href={badge.url}>
          <Tooltip title={<span style={{ fontSize: "14px" }}>{badge.title}</span>} placement="bottom">
            <img className={classes.badges} src={badge.image} alt={badge.title} width={150} height={150} />
          </Tooltip>
        </a>
      ))}
    </div>
  );
}
