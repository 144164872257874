import React, { useState, useRef, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";
import "./TodoItem.css";
import TodoItemButtons from "./TodoItemButtons";


const reorderItems = (arr, incomingId, existingId) => {
  if (incomingId === existingId) return arr;
  let newArr = [...arr];
  let incomingItem = newArr.find((el) => el.id === incomingId);
  newArr = newArr.filter((el) => el.id !== incomingId);
  if (incomingId > existingId) {
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].id < incomingId && newArr[i].id >= existingId) {
        newArr[i].id = newArr[i].id + 1;
      }
    }
  } else {
    for (let i = 0; i < newArr.length; i++) { // coming from top going to bottom
      if (newArr[i].id > incomingId && newArr[i].id <= existingId) {
        newArr[i].id = newArr[i].id - 1;
      }
    }
  }
  incomingItem.id = existingId;
  newArr.push(incomingItem);
  return newArr.sort((a, b) => (a.id > b.id ? 1 : -1));
};

export default function TodoItem({ todoItem, todoList, setTodoList }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [testHeight, setTestHeight] = useState();
  const inputRef = useRef();

  const resizeTextArea = useCallback(() => {
    let textEl = document.getElementById(`text-${todoItem.id}`);
    let textAreaEl = document.getElementById("textarea-edit");
    if (textAreaEl) {
      setTestHeight(textAreaEl.scrollHeight);
    } else if (textEl) {
      setTestHeight(textEl.scrollHeight);
    }
  }, [todoItem]);

  const handleChange = (event) => {
    let objIndex = todoList.findIndex((obj) => obj.id === todoItem.id);
    let tempArray = [...todoList];
    tempArray[objIndex].value = event.target.value;
    setTodoList(tempArray);
    resizeTextArea();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.keyCode === "13" || event.keyCode === 13) handleBlur();
  };

  const handleClick = () => {
    resizeTextArea();
    !todoItem.completed && setIsDisabled(false);
  };

  const handleBlur = () => {
    setIsDisabled(true);
  };

  useEffect(() => {
    !isDisabled && inputRef.current.focus();
  }, [isDisabled]);

  const dragStartHandler = (e) => {
    e.dataTransfer.setData("text/plain", todoItem.id);
    e.dataTransfer.effectAllowed = "move";
    
    let bounds = e.target.getBoundingClientRect();
    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;
    let elmnt = document.getElementById(`drop-area-${todoItem.id}`);
    let cln = elmnt.cloneNode(true);
    elmnt.style.opacity = 0
    cln.classList.add('dragging')
    cln.style.width = '300px'
    cln.id = `cln-drop-area-${todoItem.id}`
    cln.style.margin = '400px 0px 0px 0px'
    // cln.x = '50vh'; cln.y = '50vh'
    document.body.style.overflow = "hidden"
    document.body.appendChild(cln);
    e.dataTransfer.setDragImage(cln, x, y);
  };

  const dragEndHandler = (e) => {
    let elmnt = document.getElementById(`drop-area-${todoItem.id}`);
    elmnt.style.opacity = 1
    document.getElementById(`cln-drop-area-${todoItem.id}`)?.remove()
    document.body.style.overflow = "auto"
  };

  const dragOverHandler = (e) => {
    // console.log(e.dataTransfer)
    e.preventDefault();
    let element = document.getElementById(`drop-area-${todoItem.id}`);
    element.classList.add("droppable");
  };

  const dragLeaveHandler = (e) => {
    let element = document.getElementById(`drop-area-${todoItem.id}`);
    if (element) {
      element.classList.remove("droppable");
    }
  };

  const dropHandler = (e) => {
    e.preventDefault();
    Array.from(document.querySelectorAll('*[id^="drop-area-"]')).forEach((el) =>
      el.classList.remove("droppable")
    );
    setTodoList(reorderItems(todoList, +e.dataTransfer.getData("text/plain"), todoItem.id));
  };

  return (
    <div id={`test-${todoItem.id}`}>
      <div className={`todo-item-main ${todoItem.completed ? "completed" : ""}`}
        onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler}
        onDrop={dropHandler} id={`drop-area-${todoItem.id}`}>
        <div onClick={handleClick} onBlur={handleBlur} onKeyDown={handleKeyDown}
          onDragStart={dragStartHandler} onDragEnd={dragEndHandler}
          draggable="true" style={{ display: "flex", width: "75%", cursor: 'move' }}
          >
          {isDisabled ? (
            <Typography
              id={`text-${todoItem.id}`}
              className={`todo-item-text${todoItem.completed ? "-completed" : ""}`}
            >
              {todoItem.completed ? <i>{todoItem.value}</i> : todoItem.value}
            </Typography>
          ) : (
            <textarea
              ref={inputRef}
              className="todo-item"
              type="text"
              id="textarea-edit"
              onChange={handleChange}
              value={todoItem.value}
              disabled={isDisabled}
              style={testHeight && { height: testHeight }}
            ></textarea>
          )}
        </div>

        <TodoItemButtons todoItem={todoItem} todoList={todoList} setTodoList={setTodoList} />
      </div>
    </div>
  );
}
