import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import TickerList from "./TickerList";
import "./Stocks.css";
import PriceRange from "./PriceRange";
import StocksCard from "./StocksCard";
import HistoryChart from "./HistoryChart";
import RefreshButton from "./RefreshButton";
import { Chip } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const initChips = [
  { label: "AAPL", selected: true },
  { label: "MSFT", selected: false },
  { label: "JPM", selected: false },
  { label: "BAC", selected: false },
  { label: "TSLA", selected: false },
  { label: "GOOGL", selected: false },
  { label: "AMZN", selected: false },
  { label: "KO", selected: false },
  { label: "T", selected: false },
];

export default function Stocks() {
  const [allStocks, SetAllStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState("AAPL");
  const [stockLogo, setStockLogo] = useState("");
  const [priceData, setPriceData] = useState({});
  const [rangeData, setRangeData] = useState([]);
  const [companyCardData, setCompanyCardData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [stockHistory, setStockHistory] = useState();
  const [listOfChips, setListOfChips] = useState(initChips);
  const controllerRef = useRef();

  useEffect(() => {
    SetAllStocks(require("./tickers.json")["data"]["rows"].map((el) => el.symbol));
    // console.log(
    //     [...new Set(require('./tickers.json')['data']['rows'].map(el => el.sector))]
    //     )
  }, []);

  const handleStockChange = async (ticker) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    } // if there is already a pending request, cancel it
    const controller = new AbortController();
    controllerRef.current = controller;
    const reqOptions = {
      method: "POST",
      body: JSON.stringify({ ticker }),
      signal: controllerRef.current.signal,
    };
    try {
      const res = await fetch(`${process.env.REACT_APP_AZURE_API_URL}/stocks`, reqOptions);
      const data = await res.json();
      setStockLogo(data.logoLink);
      setPriceData(data.priceData[0]);
      setRangeData([
        { ...data.priceData[0], high: data.priceData[0]["high"] - data.priceData[0]["low"] },
      ]);
      setCompanyCardData(data.companyCardData);
      setIsLoading(false);
      // let tempHistory = data.history.map( (el) => {return {price: el.price, date: new Date(el.date)} });
      let tempHistory = data.history.map((el) => {
        return { price: el.price, date: new Date(el.date).valueOf() };
      });
      setStockHistory(tempHistory);
      controllerRef.current = null;
    } catch (e) {}
  };

  useEffect(() => {
    selectedStock && handleStockChange(selectedStock);
  }, [selectedStock]);

  const handleChips = (e) => {
    const newSelection = e.target.innerText;
    if (newSelection === selectedStock) return;
    let newChips = [];
    listOfChips.forEach((item) => {
      newChips.push({
        label: item.label,
        selected: item.label === newSelection,
      });
    });
    setListOfChips(newChips);
    setSelectedStock(newSelection);
    setIsLoading(true);
  };

  return (
    <div className="stocks-home">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className="stocks-main">
            <div className="stocks-search">
              <TickerList
                stocks={allStocks}
                selectedStock={selectedStock}
                setSelectedStock={setSelectedStock}
                setIsLoading={setIsLoading}
              />

              {isLoading ? (
                <CircularProgress style={{ marginLeft: 20, color: "rgb(23, 184, 135)" }} />
              ) : (
                <RefreshButton
                  setIsLoading={setIsLoading}
                  selectedStock={selectedStock}
                  handleStockChange={handleStockChange}
                />
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ margin: "10px 0px" }}>Suggestions: </p>
              <div className="stock-chips">
                {listOfChips.map((item) => (
                  <Chip
                    key={item.label}
                    clickable={true}
                    label={item.label}
                    variant={!item.selected ? "outlined" : "filled"}
                    onClick={handleChips}
                  />
                ))}
              </div>

              {!isLoading ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                  <div style={{ marginTop: 25 }}>
                    <StocksCard stockLogo={stockLogo} companyCardData={companyCardData} />
                  </div>
                  <div className="price-range-chart">
                    {rangeData.length > 0 && rangeData[0]["low"] && priceData && (
                      <PriceRange rangeData={rangeData} priceData={priceData} />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Skeleton style={{ margin: 25 }} variant="rectangular" width={275} height={200} />
                  <Skeleton variant="rectangular" width={75} height={300} />
                </div>
              )}
            </div>
          </div>

          {!isLoading ? (
            <div className="history-chart">
              {stockHistory && <HistoryChart stockData={stockHistory} />}
            </div>
          ) : (
            <Skeleton
              style={{ marginLeft: 15 }}
              variant="rectangular"
              width={"78%"}
              height={window.screen.width > 600 ? 600 : 200}
            />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
