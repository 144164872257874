import React from "react";
import Card from "@mui/material/Card";
import classes from "./StocksCard.module.css";
import Divider from "@mui/material/Divider";

export default function StocksCard({ stockLogo, companyCardData }) {
  return (
    <Card className={classes.container}>
      {companyCardData && (
        <div className={classes.main}>
          {/* <div> {stockLogo && <img src={stockLogo} height={25} />} </div> */}

          <div className={classes.top}>
            <b style={{ fontSize: "16px" }}>{companyCardData.companyName}</b>
            <span style={{ marginLeft: 20, fontSize: "24px" }}>{`$${companyCardData.currentPrice}`}</span>
          </div>
          <Divider width="100%" style={{ marginBottom: 8, marginTop: 2 }} />

          <div className={classes.details}>
            <div className={classes.secondary_text}>
              <span>Market Cap: </span>
              <span>{`${companyCardData.marketCap}`}</span>
            </div>
            <Divider width="100%" />

            <div className={classes.secondary_text}>
              <span>Forward PE: </span>
              <span>{companyCardData.forwardPE ? companyCardData.forwardPE : "-"}</span>
            </div>
            <Divider width="100%" />

            <div className={classes.secondary_text}>
              <span>Dividend Yield: </span>
              <span>{companyCardData.dividendRate ? `${companyCardData.dividendRate}%` : "-"}</span>
            </div>
            <Divider width="100%" />
          </div>
        </div>
      )}
    </Card>
  );
}
