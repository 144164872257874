import React from "react";
import { ReferenceLine, BarChart, Bar, YAxis, ResponsiveContainer } from "recharts";

const getDomain = (data) => {
  let min = 1_000_000;
  let max = 0;
  console.log(data);
  for (const value of Object.values(data)) {
    min = value < min ? value : min;
    max = value > max ? value : max;
  }
  console.log([min * 0.92, max * 1.02]);
  return [min * 0.92, max * 1.02];
};

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  return (
    <text x={x + width + 30} y={y} fill="white" textAnchor="middle" dy={0}>{`$${value}`}</text>
  );
};

export default function PriceRange({ rangeData, priceData }) {
  return (
      <ResponsiveContainer width="100%" height={"100%"}>
        <BarChart data={rangeData} margin={{ top: 20, right: 140, left: 90, bottom: 5 }}>
          <YAxis type="number" domain={getDomain(priceData)} allowDataOverflow={true} hide={true} />

          <Bar dataKey="low" stackId="a" fill="rgba(0,0,0,0)" label={renderCustomBarLabel} />

          <Bar
            dataKey="high"
            stackId="a"
            fill="rgba(255,255,255,0.05)"
            radius={[50, 50, 50, 50]}
            // fill="linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)"
            stroke="white"
            strokeWidth={1}
            label={renderCustomBarLabel}
          />

          <ReferenceLine
            y={priceData["current"]}
            label={{
              position: "left",
              value: `$${priceData["current"]}`,
              fill: "rgb(23, 184, 135)",
              fontSize: 20,
            }}
            stroke="rgb(23, 184, 135)"
            strokeWidth={3}
          />

          <ReferenceLine
            y={priceData["mean"]}
            label={{
              position: "right",
              value: `$${priceData["mean"]}`,
              fill: "white",
              fontSize: 16,
            }}
            stroke="white"
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        </BarChart>
      </ResponsiveContainer>
  );
}
