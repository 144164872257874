import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TableSortLabel from '@mui/material/TableSortLabel';


function createData(code, number, name, nationality, constructor, points) {
  return { code, number, name, nationality, constructor, points };
}

const columns = [
  {name: 'Code', var: 'code'},
  {name: 'Number', var: 'number'},
  {name: 'Name', var: 'name'},
  {name: 'Nationality', var: 'nationality'},
  {name: 'Constructor', var: 'constructor'},
  {name: 'Points', var: 'points'},
]


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            style={{backgroundColor: '#303030'}}
            key={column.name} align="center"
            sortDirection={orderBy === column.name ? order : false}>
            <TableSortLabel
              active={orderBy === column.var}
              direction={orderBy === column.var ? order : 'asc'}
              onClick={createSortHandler(column.var)}>
                {column.name}
                {orderBy === column.var ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function FormulaTable({drivers, setSelectedDriverIndex, setIsLoading}) {
    const [rows, setRows] = useState([])
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState('');
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    
    useEffect(() =>{
      if (drivers){
        setRows(
          drivers.sort((a,b) => (parseInt(a.points) < parseInt(b.points)) ? 1 : ((parseInt(b.points) < parseInt(a.points)) ? -1 : 0))
                  .map((driver) =>{ return createData(driver.code, parseInt(driver.permanentNumber), `${driver.givenName} ${driver.familyName}`,
                                                      driver.nationality, driver.constructor, parseInt(driver.points))
        }))
        setSelected('')
        setSelectedDriverIndex(-1)
      }
    }, [drivers, setSelectedDriverIndex])


    const handleClick = (event, name) => {
      if (name !== selected){
        setSelected(name);
        setSelectedDriverIndex(rows.findIndex( row => row.name === name))
        setIsLoading(true)
      }
    };


  return (
    <TableContainer component={Paper} style={{maxHeight: '70vh', marginBottom: 35, backgroundColor: '#343434'}}>
      
      <Table className={classes.table} stickyHeader>

      <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

      <TableBody>
        {stableSort(rows, getComparator(order, orderBy))
          .map((row, index) => {
            const isItemSelected = selected === row.name;
            return (
              <TableRow
                onClick={(event) => handleClick(event, row.name)}
                selected={isItemSelected}
                className={classes.tablecell} hover key={row.name}>
                { columns.map( column => { 
                  return <TableCell style={{cursor: 'pointer'}} key={column.name} align="center">{row[column.var] || ''}</TableCell> 
                  } ) }
              </TableRow>
            );
          })}
          
      </TableBody>

        {/* <TableHead>
          <TableRow>
            <TableCell></TableCell>
            { columns.map( column => { return <TableCell key={column.name} align="center">{column.name}</TableCell> } ) }
          </TableRow>
        </TableHead> */}

        {/* <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row"></TableCell>
              { columns.map( column => { return <TableCell align="center">{row[column.var]}</TableCell> } ) }
            </TableRow>
          ))}
        </TableBody> */}

      </Table>
    </TableContainer>
  );
}
